.video {
  margin-top: 32px;
  width: 80%;
  height: 600px;
  max-width: 960px;
}

@media only screen and (min-width: 751px) and (max-width: 1023px) {
  .video {
    height: 450px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 750px) {
  .video {
    width: 100%;
    height: 375px;
  }
}

@media only screen and (max-width: 374px) {
  .video {
    width: 100%;
    height: 250px;
  }
}

.more {
  padding-bottom: 16px;
  font-size: 32px;
}
