.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  line-height: 1.25;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: lightcoral;
}

.sub-header {
  display: flex;
  flex-direction: row;
  background-color: #bebfc2;
  min-height: 6vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: calc(8px + 2vmin);
  color: black;
  font-weight: bold;
}

.active {
  color: lightcoral;
  /* background-color: lightblue; */
}
.section {
  padding: 10px;
  cursor: pointer;
}

.section-body {
  min-height: 80vh;
}

@media only screen and (max-width: 800px) {
  .section-body {
    min-height: 100vh;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
