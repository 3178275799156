.description {
  padding: 8px;
  margin: 0px auto;
  width: 52%;
  font-size: 20px;
}

.member {
  padding: 6px;
}

.name {
  color: lightcoral;
}

image {
  margin-top: 16px;
}

img {
  margin-top: 16px;
  object-fit: contain;
  width: 600px;
  height: 600px;
}

.carousel {
  margin: 0 auto;
  width: 800px;
  max-width: 960px;
}

.nextShow {
  color: lightskyblue;
  margin-bottom: 16px;
  margin-top: 32px;
  font-size: 24px;
  width: 52%;
}

@media only screen and (min-width: 801px) and (max-width: 1200px) {
  .description {
    width: 67%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 800px) {
  .description {
    width: 60%;
  }

  .carousel {
    width: 600px;
  }

  img {
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  .description {
    width: 80%;
  }
  img {
    width: 370px;
    height: 370px;
  }
  .carousel {
    width: 300px;
  }
}

.container {
  min-width: 400px;
}
