.artist {
  font-weight: bold;
  margin-right: 6px;
  line-height: 1.5;
}

.artist.even {
  color: lightskyblue;
}

.song {
  color: lightcoral;
  margin-right: 8px;
}

.setlist {
  margin: 0 auto;
  padding-bottom: 16px;
  width: 60%;
}

.minor {
  color: lightskyblue;
}

@media only screen and (max-width: 600px) {
  .setlist {
    width: 94%;
    font-size: smaller;
  }
}