.mail {
  margin: 24px 0;
}

a {
  font-size: 32px;
  color: lightcoral;
}

ul {
  list-style: none;
}

.logo {
  opacity: 0.95;
}

.details {
  z-index: 1;
  position: relative;
}

.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.contact {
  color: lightcoral;
}

@media only screen and (max-width: 600px) {
  .social {
    font-size: smaller;
  }
  a > span {
    font-size: smaller;
  }
}

.crow-logo {
  margin-left: 8px;
  margin-top: 16px;
  max-width: 500px;
}
