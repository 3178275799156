.event {
  width: 100%;
  margin: 0 auto;
  line-height: 1;
}

.date,
.venue {
  font-weight: bold;
  color: lightskyblue;
}

.location,
.note .date-time {
  flex-basis: 100px;
}

.highlight {
  color: lightskyblue;
  font-size: 16px;
}

.club {
  font-weight: bold;
  margin-right: 12px;
  line-height: 1.5;
}

.clubs {
  width: 100%;
  padding-inline-start: 0;
}

.club.even {
  color: lightskyblue;
}

.events {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: top;
}

.poster {
  margin-top: -12px;
  max-width: 370px;
  margin-bottom: -36px;
}

.major {
  font-size: larger;
  font-weight: 600;
  color: lightcoral;
  display: "inline-block";
}

.events-wrapper {
  margin: 0 auto;
  text-align: center;
}

.float-child {
  width: 50%;
  float: left;
  padding: 20px 20px 0 20px;
  margin: 0 16px;
}

.event-details {
  margin-top: 44px;
  max-width: 370px;
}

.played {
  margin-bottom: 8px;
}

@media only screen and (max-width: 1020px) {
  .poster {
    max-width: 265px;
  }
  .float-child {
    padding: 10px;
  }
}

@media only screen and (max-width: 880px) {
  .events {
    width: 100%;
    font-size: smaller;
  }
  .clubs {
    font-size: smaller;
  }
  .poster {
    max-width: 235px;
  }
  .event-details {
    margin-top: 0;
  }
  .float-child {
    margin: 0 auto;
    padding: 10px;
  }
}
